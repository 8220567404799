'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { SVGProps } from 'react'
const ExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M11 4.999v2L6 7v12h12v-5.001h2V19a2 2 0 01-2 2H6a2 2 0 01-2-2V7a2 2 0 012-2l5-.001zM21 4v7l-1.987-.054-.009-3.543-6.844 6.844-1.414-1.414 6.817-6.818-3.491.038L14 4h7z"
    />
  </svg>
)
export default ExternalLink
