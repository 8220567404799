import { Typograhy } from '../Typograhy'

interface Props {
  offerText?: string
}

const DatePickerLegend: React.FC<Props> = ({ offerText }) => (
  <>
    {offerText && (
      <div className="ui-absolute ui-top-[49px] ui-z-20 lg:ui-top-0 ui-left-0 lg:ui-p-dfds-s ui-flex ui-w-full ui-bg-white">
        <div className="ui-w-full ui-flex  ui-p-dfds-s lg:ui-p-0 lg:ui-pb-dfds-s ui-border-solid ui-border-b ui-border-dfds-divider-dark">
          <div className="ui-mr-dfds-xs ui-block ui-h-[0] ui-w-[0] ui-border-solid ui-border-l-[0px] ui-border-r-[15px] ui-border-t-[15px] ui-border-l-transparent ui-border-r-transparent ui-border-t-dfds-secondary-dark ui-bg-transparent"></div>
          <Typograhy as="label" className=" ui-text-dfds-text-dark-primary  ">
            {offerText}
          </Typograhy>
        </div>
      </div>
    )}
  </>
)

export default DatePickerLegend
