import '@dfds-pax/ui/stylescvi.css'
import '@dfds-pax/login/styles.css'
import './globals.css'

import { MsalProvider } from '@azure/msal-react'
import Handler from './Handler'
import { useEffect, useState } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'

interface Props {
  msalInstance: PublicClientApplication
}

const App: React.FC<Props> = ({ msalInstance }) => {
  const [msalReady, setMsalReady] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      await msalInstance.initialize()
      setMsalReady(true)
    })()
  }, [])

  return (
    <div id="azure-login">
      <MsalProvider instance={msalInstance}>{msalReady && <Handler />}</MsalProvider>
    </div>
  )
}

export default App
