import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalAuthSettings } from '@dfds-pax/login'

const msalInstance = new PublicClientApplication(msalAuthSettings.config)

declare global {
  interface Document {
    azureLoginCreate?: () => void
  }
}

if (!document.azureLoginCreate) {
  document.azureLoginCreate = function () {
    ReactDOM.createRoot(document.getElementById('azure-login-root') as HTMLElement).render(
      <React.StrictMode>
        <App msalInstance={msalInstance} />
      </React.StrictMode>
    )
  }
}

if (window.location.hostname === 'localhost' || window.location.hostname.includes('azure-login')) {
  ReactDOM.createRoot(document.getElementById('azure-login-root') as HTMLElement).render(
    <React.StrictMode>
      <App msalInstance={msalInstance} />
    </React.StrictMode>
  )
}
