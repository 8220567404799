import { getContentfulLocale, getUrlLocale } from '@dfds-pax/common'
import { getClientEnvironment, logger } from '@dfds-pax/common'
import { LogProps } from '@dfds-pax/logging-provider'
import { v4 as uuidv4 } from 'uuid'

interface Item {
  fields: {
    entryTitle: string
    value: string
  }
}

interface ContentResult {
  items: Item[]
}

interface MenuItem {
  id: string
  slug: string
  title: string
}

interface SlugItem {
  sys: {
    id: string
  }
  fields: {
    slug: string
    entryTitle?: string
    value?: string
  }
}

const getTitle = (slugId: string, contentResult: ContentResult): string => {
  if (slugId === '4SQzhVFYxYPQkcy3urgjFi')
    return contentResult.items.find((item: Item) => item.fields.entryTitle === 'MENU-ONE-TITLE')?.fields?.value ?? 'NOT FOUND'
  if (slugId === '5KCuKuA1SD3nEZyStlxP9u')
    return contentResult.items.find((item: Item) => item.fields.entryTitle === 'MENU-TWO-TITLE')?.fields?.value ?? 'NOT FOUND'
  if (slugId === '5P3CeDTyiA7ugyp8Uy1QvM')
    return contentResult.items.find((item: Item) => item.fields.entryTitle === 'MENU-THREE-TITLE')?.fields?.value ?? 'NOT FOUND'
  return 'NOT FOUND'
}

const getContentfulData = async (contentTask: Promise<any>, urlSlugsTask: Promise<any>) => {
  const attempts = 3
  let cnt = 1
  while (cnt <= attempts) {
    try {
      await new Promise((resolve) => setTimeout(resolve, 300 * cnt))
      const [contentResult, urlSlugsResult] = await Promise.all([contentTask, urlSlugsTask])
      return { contentResult, urlSlugsResult }
    } catch (error: any) {
      if (cnt === attempts && ['Failed to fetch', 'Load failed'].includes(error?.message)) {
        const payload: LogProps = {
          message: error?.message,
          messageTemplate: `Error getting content from contentful after ${attempts} attempts`,
          level: 'Error',
          fields: {
            correlationId: uuidv4(),
            application: 'azure-login',
            environment: getClientEnvironment(),
            frontend: true,
            path: window.location.pathname,
            referer: document.referrer,
            userAgent: navigator.userAgent,
            error: {
              error: error?.name,
              stackTrace: error?.componentStack,
            },
          },
        }
        logger('Error', getClientEnvironment(), payload)
      } else cnt++
    }
  }
}

export default async function Content() {
  const urlLocale = getUrlLocale()
  const contentfulLocale = getContentfulLocale(urlLocale)

  const contentTaskUrl = new URL('https://cdn.contentful.com/spaces/z860498abl84/environments/master/entries')
  contentTaskUrl.searchParams.append('content_type', 'paxObResource')
  contentTaskUrl.searchParams.append('locale', contentfulLocale)
  contentTaskUrl.searchParams.append('fields.applications', 'login')
  contentTaskUrl.searchParams.append('limit', '100')

  const contentTask = httpClient(contentTaskUrl.toString(), {
    Authorization: 'Bearer cf3eb41c6603c064c84111798dd42d288c8e0262017997f894653da20797e665',
  })

  const urlSlugList = '4SQzhVFYxYPQkcy3urgjFi,5KCuKuA1SD3nEZyStlxP9u,5P3CeDTyiA7ugyp8Uy1QvM'

  const urlSlugTaskUrl = new URL('https://cdn.contentful.com/spaces/mivicpf5zews/environments/master/entries')
  urlSlugTaskUrl.searchParams.append('content_type', 'urlSlug')
  urlSlugTaskUrl.searchParams.append('locale', contentfulLocale)
  urlSlugTaskUrl.searchParams.append('sys.id[in]', urlSlugList)
  urlSlugTaskUrl.searchParams.append('limit', '100')

  const urlSlugsTask = httpClient(urlSlugTaskUrl.toString(), {
    Authorization: 'Bearer ffb6d2e75bdeb11580a166b1856d1b183e180c6da95a97998ab50cd12ad9eb85',
  })

  const _result = await getContentfulData(contentTask, urlSlugsTask)
  const contentResult = _result?.contentResult
  const urlSlugsResult = _result?.urlSlugsResult

  const result = {
    menuContent: {
      LOGOUT: contentResult.items.find((item: Item) => item.fields.entryTitle === 'LOGOUT')?.fields?.value,
      LOGIN: contentResult.items.find((item: Item) => item.fields.entryTitle === 'LOGIN')?.fields?.value,
      MY_PROFILE: contentResult.items.find((item: Item) => item.fields.entryTitle === 'MY-PROFILE')?.fields?.value,
      CREATE_NEW_ACCOUNT: contentResult.items.find((item: Item) => item.fields.entryTitle === 'CREATE-NEW-ACCOUNT')?.fields?.value,
      FIRST_TIME_HERE: contentResult.items.find((item: Item) => item.fields.entryTitle === 'FIRST-TIME-HERE')?.fields?.value,
    },
    menuItems: urlSlugsResult.items.map((item: SlugItem): MenuItem => {
      return { id: item.sys.id, slug: item.fields.slug, title: getTitle(item.sys.id, contentResult) }
    }),
  }

  return result
}

async function httpClient(url: string, headers?: HeadersInit) {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      ...headers,
    },
  }

  let response
  try {
    response = await fetch(url, requestOptions)
    if (!response.ok) {
      let responseObj
      let jsonError
      try {
        responseObj = await response.json()
      } catch (error) {
        jsonError = 'Could not parse response body as JSON'
      }
      const errorMessage =
        responseObj?.message || responseObj?.Message || responseObj?.title || jsonError || 'Message did not exist in error response.'
      const httpClientError = new Error(errorMessage)
      throw httpClientError
    }
    return await response.json()
  } catch (error: any) {
    // Network errors raised directly from 'response = await fetch(url, requestOptions)', i.e. frontend having problems contacting contentful with fetch in the browser and throws 'Failed to fetch/Load failed'
    // 'Failed to fetch' is window and linux specific
    // 'Load failed' is apple specific
    if (['Failed to fetch', 'Load failed'].includes(error?.message)) throw error
    let errorMessage = error?.message || 'Unknown fetch error'
    const payload: LogProps = {
      message: errorMessage,
      messageTemplate: 'Error getting content from contentful',
      level: 'Error',
      fields: {
        correlationId: uuidv4(),
        application: 'azure-login',
        environment: getClientEnvironment(),
        frontend: true,
        path: window.location.pathname,
        referer: document.referrer,
        userAgent: navigator.userAgent,
        error: {
          error: error?.name,
          stackTrace: error?.componentStack,
        },
      },
    }
    logger('Error', getClientEnvironment(), payload)
  }
}
