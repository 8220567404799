import { useMsal, useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react'
import { Badge, msalAuthSettings } from '@dfds-pax/login'
import { useEffect, useState } from 'react'
import { InteractionType } from '@azure/msal-browser'
import Content from './Utils/content'
import { getUrlLocale } from '@dfds-pax/common'

const Handler: React.FC = () => {
  const [menuContent, setMenuContent] = useState<any | undefined>()
  const [menuItems, setMenuItems] = useState<any[] | undefined>()

  const { instance } = useMsal()
  const isLoggedIn: boolean = useIsAuthenticated()
  const hasValidToken = useMsalAuthentication(InteractionType.None)

  const locale = getUrlLocale()

  useEffect(() => {
    instance.handleRedirectPromise().then((tokenResponse) => {
      if (tokenResponse) {
        instance.setActiveAccount(tokenResponse.account)
      }
    })
  }, [instance])

  useEffect(() => {
    ;(async () => {
      const { menuContent, menuItems } = await Content()
      setMenuContent(menuContent)
      const menuItemsOrder = ['4SQzhVFYxYPQkcy3urgjFi', '5KCuKuA1SD3nEZyStlxP9u', '5P3CeDTyiA7ugyp8Uy1QvM']
      var menuItemsSorted = menuItems?.sort((a: any, b: any) => menuItemsOrder.indexOf(a.id) - menuItemsOrder.indexOf(b.id))
      setMenuItems(menuItemsSorted)
    })()
  }, [])

  return (
    <Badge
      isLoggedIn={isLoggedIn && hasValidToken.result != null}
      email={
        (instance.getActiveAccount()?.idTokenClaims?.given_name as string) ?? (instance.getActiveAccount()?.idTokenClaims?.email as string)
      }
      menuItems={window.location.host.includes('localhost') ? menuItemsMock : menuItems}
      menuContent={window.location.host.includes('localhost') ? menuContentMock : menuContent}
      onLoginClicked={async () =>
        await instance.loginRedirect({
          scopes: msalAuthSettings.scopes,
          extraQueryParameters: { ui_locales: locale },
        })
      }
      onCreateAccountClicked={async () =>
        await instance.loginRedirect({
          scopes: msalAuthSettings.scopes,
          extraQueryParameters: { flowState: 'signup', ui_locales: locale },
        })
      }
      onLogoutClicked={async () => {
        sessionStorage.removeItem('pax_Token')
        sessionStorage.removeItem('pax_Sub')
        document.cookie = `pax_AuthType=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`
        await instance.logout()
      }}
    />
  )
}

export default Handler

const menuContentMock = {
  LOGIN: 'Log in',
  LOGOUT: 'Log out',
  MY_PROFILE: 'My profile',
  CREATE_NEW_ACCOUNT: 'Create a new account',
  FIRST_TIME_HERE: 'First time here?',
}

const menuItemsMock = [
  {
    id: '4SQzhVFYxYPQkcy3urgjFi',
    slug: 'passenger-ferries/account/my-profile',
    title: 'Personal details',
  },
  {
    id: '5KCuKuA1SD3nEZyStlxP9u',
    slug: 'passenger-ferries/account/bookings',
    title: 'My bookings',
  },
  {
    id: '5P3CeDTyiA7ugyp8Uy1QvM',
    slug: 'passenger-ferries/account/consent-privacy',
    title: 'Consent & privacy',
  },
]
