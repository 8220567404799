'use client'

import { SVGProps } from 'react'

const ChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path fill="currentColor" d="M7.41 8L12 12.945 16.59 8 18 9.522 12 16 6 9.522z" />
  </svg>
)

export default ChevronDown
