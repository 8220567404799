import { useEffect, useState } from 'react'

export function focusElement(selector: string): void {
  const element: HTMLElement | null = document.querySelector(selector)
  if (element) {
    element.focus()
  }
}

export const useWindowWidth = (): {
  isMobile: boolean
  isTablet: boolean
} => {
  const mobileBreakpoint = () => getWindowWidth().width < 768
  const tabletBreakpoint = () => getWindowWidth().width > 767 && getWindowWidth().width <= 1025
  const getWindowWidth = () => {
    const { innerWidth: width } = window
    return {
      width,
    }
  }

  const [isMobile, setIsMobile] = useState<boolean>(mobileBreakpoint())
  const [isTablet, setIsTablet] = useState<boolean>(tabletBreakpoint())

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(mobileBreakpoint())
      setIsTablet(tabletBreakpoint())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobile, isTablet }
}

export interface DatesData {
  defaultDate: string
  dates: {
    fromDate: string
    toDate: string
  }
  disabledDates: string[]
  offerDates: string[]
  offerText?: string
}
