'use client'

import { MutableRefObject, forwardRef, useEffect, useRef, useState } from 'react'
import Label from './Label'
import { Combobox } from '@headlessui/react'
import AssistiveLabel from './AssistiveLabel'
import ChevronDown from '../Icons/ChevronDown'

type FieldOption = { value: string; text: string }

export type SelectFieldProps = {
  label?: string
  name?: string
  selectedValue?: FieldOption
  options: FieldOption[]
  required?: boolean
  autocomplete?: string
  readOnly?: boolean
  disabled?: boolean
  placeholder?: string
  assistiveText?: string
  errorMessage?: string
  onChange?: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
}

export const SelectField = forwardRef<HTMLInputElement, SelectFieldProps>(
  (
    {
      label,
      name,
      selectedValue,
      autocomplete,
      readOnly,
      disabled,
      placeholder,
      options,
      required,
      assistiveText,
      errorMessage,
      onChange,
      onBlur,
      onFocus,
      ...rest
    },
    ref
  ) => {
    const [selectedOption, setSelectedOption] = useState<FieldOption | null>(null)
    const [query, setQuery] = useState('')

    useEffect(() => {
      const option = options.find((o) => o.value === selectedValue?.value) ?? null
      setSelectedOption(option)
    }, [selectedValue])

    const buttonRef: MutableRefObject<any> = useRef(null)

    const filteredOptions =
      query === ''
        ? options
        : options.filter((option) => option?.text.toLowerCase().replace(/\s+/g, '').startsWith(query.toLowerCase().replace(/\s+/g, '')))

    return (
      <div
        className="ui-flex ui-flex-col ui-w-full"
        data-e2e={name}
        data-e2e-disabled={disabled ? true : false}
        data-e2e-error={errorMessage != null ? true : false}
      >
        {label && <Label label={label} required={required ?? false} disabled={disabled ?? false} />}
        <div
          className={`ui-border ui-border-1 focus-within:ui-shadow-fieldFocus focus-within:ui-border-dfds-secondary-main ui-w-full ${
            errorMessage && 'ui-border-dfds-status-alert'
          }`}
        >
          <div
            className={`ui-relative ui-border ui-border-1 focus-within:ui-shadow-fieldFocus focus-within:ui-border-dfds-secondary-main ui-w-full ${
              errorMessage
                ? 'ui-shadow-fieldError ui-border-dfds-status-alert'
                : disabled
                  ? 'ui-border-dfds-text-dark-disabled'
                  : 'ui-border-dfds-text-dark-secondary'
            } ui-bg-dfds-surface-light`}
          >
            <Combobox
              value={selectedOption}
              disabled={disabled}
              onChange={(option: FieldOption) => {
                if (onChange && option) {
                  setSelectedOption(option)
                  onChange(option.value)
                }
              }}
            >
              <div className="ui-relative">
                <div
                  onClick={() => {
                    buttonRef?.current?.click()
                    setQuery('')
                  }}
                  className="ui-relative ui-w-full ui-cursor-default ui-overflow-hidden ui-rounded-lg ui-bg-white ui-text-left focus:ui-outline-none focus-visible:ui-ring-2 ui-focus-visible:ui-ring-white/75 focus-visible:ui-ring-offset-2 focus-visible:ui-ring-offset-teal-300 sm:ui-text-sm"
                >
                  <Combobox.Input
                    onBlur={onBlur && onBlur}
                    readOnly={readOnly ?? false}
                    onFocus={(event: any) => event.target.hasAttribute('readOnly') && event.target.removeAttribute('readOnly')}
                    name={name}
                    placeholder={placeholder}
                    autoComplete={autocomplete ?? 'off'}
                    ref={ref}
                    {...rest}
                    className={`ui-h-[40px] ui-pl-dfds-s ui-bg-dfds-surface-light ui-text-base ui-outline-none ui-rounded-none ui-border-none ui-w-full ${
                      disabled ? 'ui-text-dfds-text-dark-disabled ui-cursor-default' : 'ui-text-dfds-text-dark-primary ui-cursor-pointer'
                    }`}
                    displayValue={(option: FieldOption) => option?.text}
                    onChange={(event) => {
                      setQuery(event.target.value)
                    }}
                    data-value={selectedOption?.value}
                  />
                  <Combobox.Button
                    ref={buttonRef}
                    className={`${
                      disabled ? 'ui-text-dfds-text-dark-disabled' : 'ui-text-dfds-secondary-main'
                    } ui-absolute ui-inset-y-0 ui-right-0 ui-flex ui-items-center ui-pr-dfds-xs`}
                  >
                    <ChevronDown width={24} height={24} />
                  </Combobox.Button>
                </div>
                <Combobox.Options className="ui-z-[10] ui-absolute ui-mt-1 ui-max-h-60 ui-w-full ui-overflow-auto ui-rounded-md ui-bg-white ui-py-1 ui-text-base ui-shadow-lg ui-ring-1 ui-ring-black/5 focus:ui-outline-none sm:ui-text-sm">
                  {filteredOptions.length === 0 && query !== '' ? (
                    <div className="ui-text-DFDS ui-relative ui-cursor-default ui-select-none ui-p-dfds-s ui-text-dfds-text-dark-primary">
                      Nothing found.
                    </div>
                  ) : (
                    filteredOptions.map((option) => (
                      <Combobox.Option
                        data-e2e-value={option.value}
                        key={option.value}
                        className={({ active }) =>
                          `ui-relative ui-cursor-pointer ui-select-none ui-py-2 ui-px-dfds-s ui-text-base ${
                            active ? 'ui-bg-dfds-listitem-selected ui-text-dfds-text-dark-primary' : 'ui-text-dfds-text-dark-primary'
                          }`
                        }
                        value={option}
                      >
                        {({}) => <span className={`ui-block ui-truncate ui-text-base`}>{option.text}</span>}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </div>
            </Combobox>
          </div>
        </div>
        <AssistiveLabel assistiveText={assistiveText} errorMessage={errorMessage} />
      </div>
    )
  }
)

export default SelectField
