'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { SVGProps } from 'react'
const SvgAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12.453 14a10 10 0 019.417 6.637L22 21H2l.13-.363A10 10 0 0111.547 14h.906zM12 3a5 5 0 11-.001 10.001A5 5 0 0112 3z"
    />
  </svg>
)
export default SvgAccount
