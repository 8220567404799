interface Props {
  id: string
  title: string
  slug: string
  disabled: boolean
  dataE2eAttr: string
}

const getUrlLocale = (typeof window !== 'undefined' && window.location.pathname.split('/')[1]) || 'en'

const getHref = (slug: string) => {
  return typeof window !== 'undefined' ? window.location.origin + '/' + getUrlLocale + '/' + slug : ''
}

export const MenuItem: React.FC<Props> = ({ id, title, slug, disabled, dataE2eAttr }) => {
  return (
    <a
      data-e2e-content-slug-id={id}
      data-e2e-content-slug={slug}
      href={getHref(slug)}
      data-e2e={dataE2eAttr}
      className={`
        login-leading-[20px] 
        login-p-dfds-s
        login-flex 
        login-items-center 
        login-border-0 
        login-border-b 
        login-border-solid 
        login-no-underline
        login-border-dfds-divider-dark 
        hover:login-bg-dfds-listitem-hover 
        ${disabled ? 'login-cursor-not-allowed login-pointer-events-none' : 'login-cursor-pointer'}
      `}
    >
      <div
        className={`login-dfds-body-interface ${
          disabled ? 'login-text-dfds-text-primary-disabled' : 'login-text-dfds-text-primary-primary'
        }`}
      >
        {title}
      </div>
    </a>
  )
}
