'use client'

import clsx from 'clsx'
import SvgExternalLink from '../Icons/ExternalLink'
import React, { ReactNode } from 'react'
import Spinner from '../Icons/Spinner'
import { motion } from 'motion/react'

export type ButtonVariation = 'Primary' | 'Secondary' | 'Outlined' | 'Danger' | 'Text' | 'Custom'

export type BaseButtonProps = {
  variation: ButtonVariation
  children?: string | ReactNode
  submittingText?: string
  disabled?: boolean
  size?: 'medium' | 'small'
  type?: 'submit' | 'reset' | 'button' | undefined
  className?: string
  submitting?: boolean
  external?: boolean
  dataAttr?: string
  bold?: boolean
  onClick?: () => void
}

type SubmittingSpinnerOverlayProps = {
  submittingText?: string
  size?: 'medium' | 'small'
}

export const SubmittingSpinnerOverlay: React.FC<SubmittingSpinnerOverlayProps> = ({ submittingText, size }) => {
  return (
    <div className={`px-dfds-s ui-flex ui-items-center ui-justify-center ui-z-10 ui-absolute ui-top-0 ui-left-0 ui-right-0 ui-bottom-0`}>
      <motion.div animate={{ rotate: 360 }} transition={{ ease: 'linear', duration: 1.5, repeat: Infinity }}>
        <Spinner height={25} width={25} />
      </motion.div>
      {submittingText && (
        <div className={clsx('ui-ml-dfds-xs ui-truncate', size === 'small' ? 'dfds-label' : 'dfds-label-bold')}>{submittingText}</div>
      )}
    </div>
  )
}

export const Button: React.FC<BaseButtonProps> = ({
  variation,
  type,
  submittingText,
  external,
  disabled,
  submitting,
  className,
  children,
  size = 'medium',
  bold = true,
  onClick,
  dataAttr,
  ...rest
}: BaseButtonProps) => {
  return (
    <button
      type={type}
      data-e2e={dataAttr}
      title={String(children)}
      disabled={disabled}
      className={clsx(
        clsx(
          'ui-relative',
          'ui-rounded-sm',
          'ui-flex',
          'ui-px-dfds-s',
          'ui-items-center',
          'ui-text-center',
          'ui-justify-center',
          'ui-w-full',
          'md:ui-w-fit',
          size === 'small' ? 'ui-h-[32px]' : 'ui-h-[44px]',
          variation === 'Primary' && 'ui-bg-dfds-status-warning ui-text-dfds-text-light-primary',
          variation === 'Secondary' && 'ui-bg-dfds-secondary-main ui-text-dfds-text-light-primary',
          variation === 'Outlined' &&
            'ui-border-solid ui-border-2 ui-border-dfds-secondary-main ui-text-dfds-secondary-main hover:ui-outline-dfds-secondary-dark hover:ui-text-dfds-secondary-dark',
          variation === 'Danger' && 'ui-border-solid ui-border-2 ui-border-dfds-status-alert ui-text-dfds-status-alert',
          variation === 'Text' && 'ui-border-none ui-text-dfds-secondary-main ui-px-0',

          disabled || submitting
            ? clsx(
                'ui-cursor-not-allowed ui-opacity-80',
                !submitting && 'ui-bg-dfds-text-dark-disabled',
                disabled && 'active:ui-opacity-80 ui-opacity-30'
              )
            : 'ui-cursor-pointer hover:ui-opacity-90 active:ui-opacity-80'
        ),
        className
      )}
      onClick={() => !disabled && !submitting && onClick && onClick()}
      {...rest}
    >
      <span
        className={clsx(
          'ui-text-ellipsis ui-whitespace-nowrap ui-overflow-hidden',
          submitting && 'ui-text-transparent',
          size === 'small' ? 'ui-dfds-label' : 'ui-dfds-label-bold'
        )}
      >
        {children}
      </span>
      {external && <div className="ui-ml-dfds-xs">{<SvgExternalLink height={20} width={20} />}</div>}
      {submitting && <SubmittingSpinnerOverlay size={size ?? 'medium'} submittingText={submittingText} />}
    </button>
  )
}
