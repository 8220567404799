export type AssistiveLabelProps = {
  assistiveText?: string
  errorMessage?: string
}

export const AssistiveLabel: React.FC<AssistiveLabelProps> = ({ assistiveText, errorMessage }) => {
  if (errorMessage) return <div className={`dfds-caption ui-mt-dfds-xxs ui-text-dfds-status-alert`}>{errorMessage}</div>
  if (assistiveText) return <div className={`dfds-caption ui-mt-dfds-xxs ui-text-dfds-text-primary-primary`}>{assistiveText}</div>
  return null
}

export default AssistiveLabel
