import { useEffect, useRef, useState } from 'react'
import { Account } from '@dfds-pax/ui'
import { Menu } from './Menu'

interface Props {
  isLoggedIn: boolean
  email: string | undefined
  menuItems: any[] | undefined
  menuContent: any | undefined
  onLoginClicked: () => void
  onLogoutClicked: () => void
  onCreateAccountClicked: () => void
}

export const Badge: React.FC<Props> = ({
  isLoggedIn,
  email,
  menuItems,
  menuContent,
  onLoginClicked,
  onLogoutClicked,
  onCreateAccountClicked,
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const badgeRef = useRef<any>(null)

  useEffect(() => {
    const handleClickOutsideSelf = (event: any) => {
      if (badgeRef.current && !badgeRef.current.contains(event.target)) setShowMenu(false)
    }

    addEventListener('mousedown', handleClickOutsideSelf)
    return () => removeEventListener('mousedown', handleClickOutsideSelf)
  }, [badgeRef])

  return (
    <div className="login-relative login-flex login-select-none">
      <div ref={badgeRef}>
        <div
          id="login-badge"
          data-e2e-loggedin={isLoggedIn}
          onClick={() => setShowMenu(!showMenu)}
          className={`login-ml-auto login-text-dfds-primary-main login-flex login-items-center login-cursor-pointer login-justify-center hover:login-bg-dfds-listitem-hover hover:login-border-dfds-secondary-main hover:login-border hover:login-border-solid login-h-[44px] login-w-[48px]`}
        >
          {!isLoggedIn ? (
            <Account height={25} width={25} />
          ) : (
            <div className="login-rounded-full login-h-[30px] login-w-[30px] login-bg-dfds-primary-main login-text-white login-flex login-items-center login-justify-center">
              <div className="login-dfds-label-bold">{getCredentials(email)}</div>
            </div>
          )}
        </div>
        {showMenu && (
          <Menu
            menuItems={menuItems}
            disabled={!isLoggedIn}
            menuContent={menuContent}
            onLoginClicked={onLoginClicked}
            onLogoutClicked={onLogoutClicked}
            onCreateAccountClicked={onCreateAccountClicked}
          />
        )}
      </div>
    </div>
  )
}

const getCredentials = (email: string | undefined) => {
  if (!email) return '-'
  return email.slice(0, 1).toUpperCase()
}
