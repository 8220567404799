import { Button } from '@dfds-pax/ui'
import { MenuItem } from './MenuItem'

interface Props {
  disabled: boolean
  menuItems: any[] | undefined
  menuContent: any | undefined
  onLogoutClicked: () => void
  onLoginClicked: () => void
  onCreateAccountClicked: () => void
}

export const Menu: React.FC<Props> = ({ disabled, menuItems, menuContent, onLogoutClicked, onLoginClicked, onCreateAccountClicked }) => {
  return (
    <div
      id="login-menu"
      className="login-bg-dfds-surface-light login-z-overlay mobile:login-fixed md:login-absolute login-top-[50px] mobile:login-left-4 login-right-4 md:login-min-w-[350px] login-shadow-xl login-rounded-lg"
    >
      <div className="login-leading-[28px] login-bg-dfds-primary-primary login-flex login-items-center login-p-dfds-s login-border-0 login-border-b login-border-solid login-border-dfds-divider-dark login-rounded-t-lg">
        <div className="login-dfds-small-headline login-text-dfds-text-light-primary">{menuContent?.MY_PROFILE}</div>
      </div>
      {menuItems?.map((item, i) => (
        <MenuItem
          dataE2eAttr={`MENU-${i === 0 ? 'ONE' : i === 1 ? 'TWO' : 'THREE'}-TITLE`}
          key={item.id}
          disabled={disabled}
          id={item.id}
          slug={item.slug}
          title={item.title}
        />
      ))}
      {disabled ? (
        <div className="login-flex login-flex-col login-items-center login-px-dfds-s login-pt-dfds-s login-rounded-b-lg">
          <div data-e2e-login className="login-w-full">
            <Button variation="Primary" className="ui-w-full" onClick={onLoginClicked}>
              {menuContent?.LOGIN}
            </Button>
          </div>
          <div className="login-w-full login-py-dfds-xs login-flex login-items-baseline login-gap-x-2 login-justify-start">
            <div className="login-dfds-label login-text-dfds-text-dark-primary">{menuContent?.FIRST_TIME_HERE}</div>
            <Button variation="Text" onClick={onCreateAccountClicked}>
              {menuContent?.CREATE_NEW_ACCOUNT}
            </Button>
          </div>
        </div>
      ) : (
        <div data-e2e-logout className="login-flex login-items-center login-px-dfds-s login-rounded-b-lg login-p-dfds-s">
          <Button variation="Outlined" className="ui-w-full" onClick={onLogoutClicked}>
            {menuContent?.LOGOUT}
          </Button>
        </div>
      )}
    </div>
  )
}
