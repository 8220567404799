import { forwardRef } from 'react'
import Label from './Label'
import AssistiveLabel from './AssistiveLabel'

export type TextFieldProps = {
  id?: string
  label?: string
  name?: string
  value?: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  assistiveText?: string
  errorMessage?: string
  autocomplete?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  defaultValue?: string
  icon?: React.ElementType
  inputType?: 'email' | 'text' | 'tel' | 'time' | 'url' | 'date' | 'file' | 'number' | 'search' | 'password'
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      id,
      name,
      label,
      placeholder,
      value,
      defaultValue,
      onChange,
      onFocus,
      onBlur,
      assistiveText,
      errorMessage,
      autocomplete,
      disabled,
      required = false,
      inputType = 'text',
      ...rest
    }: TextFieldProps,
    ref
  ) => {
    return (
      <div className="ui-flex ui-flex-col" data-e2e={name} data-e2e-disabled={disabled ?? false} data-e2e-error={errorMessage != null}>
        {label && <Label id={id} label={label} required={required ?? false} disabled={disabled ?? false} />}
        <div
          className={`ui-border ui-border-1 focus-within:shadow-fieldFocus focus-within:ui-border-dfds-secondary-main ui-w-full ${
            errorMessage && 'ui-border-dfds-status-alert'
          }`}
        >
          <div
            className={`ui-border ui-border-1 focus-within:shadow-fieldFocus focus-within:ui-border-dfds-secondary-main ui-w-full ${
              errorMessage
                ? 'ui-shadow-fieldError ui-border-dfds-status-alert'
                : disabled
                  ? 'ui-border-dfds-text-dark-disabled'
                  : 'ui-border-dfds-text-dark-secondary'
            } ui-bg-dfds-surface-light`}
          >
            <input
              id={id}
              ref={ref}
              type={inputType}
              autoComplete={autocomplete}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={disabled}
              placeholder={placeholder}
              className={`ui-h-[40px] ui-px-dfds-s ui-bg-dfds-surface-light ui-text-base ui-outline-none ui-rounded-none ui-border-none ui-w-full ${
                disabled ? 'ui-text-dfds-text-dark-disabled' : 'ui-text-dfds-text-dark-primary'
              }`}
              defaultValue={defaultValue}
              value={value}
              data-value={value}
              {...rest}
            />
          </div>
        </div>
        <AssistiveLabel assistiveText={assistiveText} errorMessage={errorMessage} />
      </div>
    )
  }
)

export default TextField
